import ConstantTool from "@/services/tool/ConstantTool";
import Response from "@/models/responses/Response";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import {Vue} from "vue-property-decorator";
import SnackbarModule from "@/store/SnackbarModule";
import Product from "@/models/Product";
import axios from "axios";
import StringTool from "@/services/tool/StringTool";

export default class ProductService {

    static async getProducts(
        component: Vue, products: Product[], page: number, size: number, search: string, categories: number[] | null,
        featured: boolean | null, orderByActivationDate: boolean | null, hasVideo: boolean | null
    ) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/products", {
                params: {
                    page, size, search, orderByActivationDate,
                    productCategoryIds: categories?.length == 0 ? null : categories?.toString(),
                    featured, hasVideo
                }
            })

            let list = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            products.splice(0, products.length)
            list.forEach(p => products.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los productos")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }


    static async getProduct(component: Vue, productId: number) {
        // @ts-ignore
        component.progress = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/products/" + productId)
            // @ts-ignore
            component.product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener el producto.")
        } finally {
            // @ts-ignore
            component.progress = false
        }
    }

    static patchProduct(component: Vue, product: Product, productId: number) {
        (<any>component).loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/products/" + productId,
            product, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar el producto"))
            .finally(() => (<any>component).loading = false)
    }

    static async patchProductDocuments(component: Vue, productId: number, image: File | null, document: File | null) {
        // @ts-ignore
        component.loading = true
        try {

            let formData: FormData = new FormData()
            if (image) { formData.set("image", image) }
            if (document) { formData.set("document", document) }

            let response = await component.axios.patch(ConstantTool.BASE_URL + "/api/products/" + productId + "/documents", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("Los documentos se han actualizado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido actualizar los documentos.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async clearProductDocuments(component: Vue, productId: number) {
        // @ts-ignore
        component.loading = true
        try {
            let response = await component.axios.patch(ConstantTool.BASE_URL + "/api/products/" + productId + "/documents/clear", null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("Los documentos se han eliminado con extito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido eliminar los documentos.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async relateProductCategory(component: Vue, productId: number, productCategoryId: number) {
        (<any>component).loading = true
        component.axios.patch(`${ConstantTool.BASE_URL}/api/product-category/${productCategoryId}/product/${productId}/relate`, null, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
        .then(() => { (<any>component).refresh() })
        .catch(() => getModule(SnackbarModule).makeToast("No se ha podido añadir la categoría al producto."))
        .finally(() => { (<any>component).loading = false })
    }

    static async unrelateProductCategory(component: Vue, productId: number, productCategoryId: number) {
        (<any>component).loading = true
        component.axios.patch(`${ConstantTool.BASE_URL}/api/product-category/${productCategoryId}/product/${productId}/unrelate`, null, {
            headers: { Authorization: getModule(SessionModule).session.token }
        })
        .then(() => { (<any>component).refresh() })
        .catch(() => getModule(SnackbarModule).makeToast("No se ha podido eliminar la categoría del producto."))
        .finally(() => { (<any>component).loading = false })
        
    }



    //CHECKPOINT
    static async patchProduct2(id: number, request: Product): Promise<Response<Product>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/products/" + id, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
            return Promise.resolve({ result: product })
        } catch (e) { return Promise.reject(e) }
    }

    static async getPublicProduct(id: number): Promise<Response<Product>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/products/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const product = JsonTool.jsonConvert.deserializeObject(response.data, Product)
            return Promise.resolve({ result: product })
        } catch (e) { return Promise.reject(e) }
    }

    static async getProducts2(
        page: number, size: number, search: string, categories: Nullable<number[]>,
        featured: boolean | null, orderByActivationDate: boolean | null, hasVideo: Nullable<boolean>
    ) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/products", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: {
                    page, size, search, orderByActivationDate, featured, hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories?.toString()
                }})
            const products = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: products, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }

    static async getPublicProducts(
        page: number, size: number, search: string, categories: Nullable<number[]>,
        featured: boolean | null, orderByActivationDate: boolean | null, hasVideo: Nullable<boolean>
    ): Promise<Response<Product[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/products", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: {
                    page, size, search, orderByActivationDate, featured, hasVideo,
                    productCategoryIds: categories?.length == 0 ? null : categories?.toString(),

                }})
            const products = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: products, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }

    static async setEnabled(id: number, enabled: boolean): Promise<Response<Product>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/product/" + id + "/enabled", null, {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { enabled }
            })
            const products = JsonTool.jsonConvert.deserializeObject(response.data, Product)
            return Promise.resolve({ result: products  })
        } catch (e) { return Promise.reject(e) }
    }

    static async getSudoProducts(
        page: number, size: number, search: string, categories: Nullable<number[]>,
        enabled: Nullable<boolean>, featured: Nullable<boolean>, hasVideo: Nullable<boolean>, blocked: Nullable<boolean>,
        uncategorized: Nullable<boolean>
    ): Promise<Response<Product[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/admin/product", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: {
                    page, size, search, enabled, hasVideo, featured, blocked, uncategorized,
                    productCategoryIds: StringTool.getIdsArray(categories)
                }})
            const products = JsonTool.jsonConvert.deserializeArray(response.data, Product)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: products, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }

}
